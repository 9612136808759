import { theme as defaultTheme, Theme } from "@hurleymc/components"

const theme: Partial<Theme> = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    default: defaultTheme.colors.named.curiousBlue,
    primary: defaultTheme.colors.named.hurleyFoundation,
  },
}

export default theme
