import React from "react"
import { Helmet } from "react-helmet"
// import { useSiteMetadata } from "../hooks/use-site-metadata"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description: customDescription,
  lang,
  meta,
  title: customTitle,
  image,
}) {
  // const { title, description, logo } = useSiteMetadata()

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            logo
          }
        }
      }
    `
  )
  const { title, description, logo } = site.siteMetadata

  const metaTitle = customTitle ? `${customTitle} | ${title}` : title
  const metaDescription = customDescription || description
  const metaImage = image || logo
  const titleTemplate = title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${titleTemplate}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `twitter:image`,
          content: metaImage,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
