import React from "react"
import { Helmet } from "react-helmet"

const Head = () => {
  return (
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Caveat&family=Open+Sans:wght@300;400;600;700&family=Quicksand:wght@400;500;700&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet"
      />
      <link
        href="https://cdnjs.cloudflare.com/ajax/libs/10up-sanitize.css/5.0.0/sanitize.min.css"
        rel="stylesheet"
      />
      <link
        href="https://use.fontawesome.com/releases/v6.4.2/css/brands.css"
        rel="stylesheet"
      />
      <link
        href="https://use.fontawesome.com/releases/v6.4.2/css/solid.css"
        rel="stylesheet"
      />
      <link
        href="https://use.fontawesome.com/releases/v6.4.2/css/fontawesome.css"
        rel="stylesheet"
      />
    </Helmet>
  )
}

export default Head
