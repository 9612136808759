import React from "react"
import styled, { ThemeProvider } from "styled-components"
import { connect } from "react-redux"

import theme from "../theme"

import {
  Reboot,
  MaxWidthContainer,
  Row,
  selectHeader,
} from "@hurleymc/components"

import Head from "./head"
import Header from "./header"
import Footer from "./footer"

const Shell = styled.div`
  display: block;
  min-height: 100%;
  min-height: 100vh;

  .nav-search {
    visibility: hidden;
  }
`

const Content = styled(MaxWidthContainer)`
  background: white;
  background-color: white;
  min-height: 100%;
  min-height: 100vh;
`

const Main = styled.main`
  width: 100%;
`

const withState = connect((state) => ({ header: selectHeader(state) }))

const Layout = withState((props) => {
  const { children } = props
  return (
    <ThemeProvider theme={theme}>
      <Shell>
        <Reboot />
        <Head />
        <Header {...props} />
        <Content className={"content"}>
          <Row>
            <Main>{children}</Main>
          </Row>
        </Content>
        <Footer />
      </Shell>
    </ThemeProvider>
  )
})

export default Layout
