import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled, { css } from "styled-components"

import {
  Header as HurleyHeader,
  Typography,
  HurleyThemeProps,
  media,
  SnipcartCart,
} from "@hurleymc/components"

import { Link } from "./link"

const CtaDesktop = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};

  .cta {
    padding: 0.625rem 2rem;
  }
`

const ctaDesktop = () => (
  <CtaDesktop>
    <Typography
      className="cta"
      color="inherit"
      component={Link}
      hover={css`
        &:hover {
          color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mercury};
        }
      `}
      to="/donate"
      variant="button"
    >
      Donate
    </Typography>
  </CtaDesktop>
)

const CtaMobile = styled.div`
  background-color: ${({ theme }: HurleyThemeProps) => theme.colors.primary};
  color: white;
  display: inline-flex;

  .cta {
    padding: 0.625rem 2rem;
  }
  // color: white;
  // margin: 0.25rem 0;
  // padding: 0.5rem 0;

  // .cta {
  //   display: block;
  // }
`

const ctaMobile = () => (
  <CtaMobile>
    <Typography
      className="cta"
      color="inherit"
      component={Link}
      hover={css`
        &:hover {
          color: ${({ theme }: HurleyThemeProps) => theme.colors.named.mercury};
        }
      `}
      to="/donate"
      variant="button"
    >
      Donate
    </Typography>
  </CtaMobile>
)

const Logo = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  max-height: 100%;

  a {
    display: block;
  }

  img {
    max-height: 4rem;
  }

  .logo-divider {
    background-color: ${({ theme }: HurleyThemeProps) =>
      theme.colors.named.silver};
    display: block;
    height: 3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    min-width: 1px;
    width: 1px;
  }

  ${media.breakpoint.up(
    "sm",
    css`
      img {
        max-height: 5rem;
      }

      .logo-divider {
        height: 5rem;
        margin-left: 1rem;
        margin-right: 1rem;
      }
    `
  )}
`

const logo = () => (
  <Logo>
    <Link to="/">
      <img
        src="https://images.files.edit.hurleymc.com/Logos/hurley-foundation.png"
        alt=""
      />
    </Link>
    <div className="logo-divider" />
    <a
      href="https://hurley.childrensmiraclenetworkhospitals.org/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://images.files.edit.hurleymc.com/Logos/cmn-hospitals.png"
        alt=""
      />
    </a>
  </Logo>
)

const Header = (props) => {
  const { craft } = useStaticQuery(
    graphql`
      {
        craft {
          entries(level: 1, section: ["hfPage"], site: "hurleyFoundation") {
            title
            uri
          }
        }
      }
    `
  )
  const { entries } = craft
  // We need to manually add the events page
  const navigation = [
    {
      title: "Events",
      uri: "events",
    },
  ].concat(entries)
  return (
    <>
      <HurleyHeader
        components={{ Link }}
        dispatch={props.dispatch}
        header={props.header}
        nav={{
          cta: { desktop: ctaDesktop, mobile: ctaMobile },
          logo,
          pages: navigation,
          sites: [
            {
              active: false,
              href: "https://www.hurleymc.com",
              title: "Hurley Medical Center",
            },
            {
              active: true,
              to: "/",
              title: "Hurley Foundation",
            },
            {
              active: false,
              href: "https://education.hurleymc.com",
              title: "Medical Education",
            },
          ],
        }}
        {...props}
      />
      <SnipcartCart />
    </>
  )
}

export default Header
